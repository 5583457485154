<template>
  <v-container>
    <v-form ref="form" v-model="valid" class="mt-5">
      <k-field-group language-prefix="client.fields">
        <v-col cols="12" lg="6" md="8" offset-lg="3" offset-md="2" offset-sm="1" sm="10">
          <v-subheader class="pa-0">{{ $tc('client.title', 1) }}</v-subheader>
          <v-card>
            <v-card-text>
              <KTextField v-model="values.name" field="name" required/>
              <KCheckbox v-model="values.isActive" field="isActive"/>
            </v-card-text>
          </v-card>
        </v-col>
      </k-field-group>
    </v-form>
  </v-container>
</template>

<script lang="js">
import Client from '@/application/models/client.js';
import KFieldGroup from '@/components/crud/fields/KFieldGroup.vue';
import KTextField from '@/components/crud/fields/KTextField.vue';
import Form from '@/components/VuetifyResourceBaseForm.vue';
import KCheckbox from '@/components/crud/fields/KCheckbox.vue';

export default {
  name: 'ClientForm',
  extends: Form,
  components: {
    KCheckbox,
    KTextField,
    KFieldGroup,
  },
  data: () => ({
    values: new Client(),
  }),
};
</script>
